import { createFileRoute } from '@tanstack/react-router';
import { useForm, FormProvider } from 'react-hook-form';
import type { z as zod } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from 'react-toastify';
import { Button } from '@tremor/react';
import { Trans } from '@lingui/macro';

import { Wrapper, PageForm } from '@/components';
import { handleFormError } from '@/utils';
import {
  usePageSuspense,
  useUpdatePage,
  getPageQueryKey,
  getPagesQueryKey,
  getPageSuspenseQueryOptions,
} from '@/api';
import { pageSchema } from '@/schemas';

export const Route = createFileRoute('/_authenticated/pages/$pageId/edit')({
  loader: async ({ context: { queryClient }, params: { pageId } }) =>
    queryClient.ensureQueryData(getPageSuspenseQueryOptions(Number(pageId))),
  component: EditPage,
});

function EditPage() {
  const pageId = Route.useParams({
    select: ({ pageId }) => Number(pageId),
  });
  const { data: page } = usePageSuspense(pageId);
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { mutateAsync } = useUpdatePage({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getPagesQueryKey(),
        });
        await queryClient.invalidateQueries({
          queryKey: getPageQueryKey(pageId),
        });
      },
    },
  });

  const methods = useForm<zod.infer<typeof pageSchema>>({
    resolver: zodResolver(pageSchema),
    defaultValues: {
      ...page,
      image: page.image?.versions?.large?.url,
      documents: page.documents?.map((document) => ({
        ...document,
        documentId: document.id,
      })),
    },
  });

  const onSave = methods.handleSubmit(async (data) => {
    try {
      const response = await mutateAsync({
        id: page.id,
        data: {
          ...data,
          image: data.image instanceof File ? data.image : undefined,
          documents: data.documents?.map((document) => ({
            id: document.documentId,
            name: document.name,
            file: document.file instanceof File ? document.file : undefined,
            _destroy: document._destroy,
          })),
        },
      });
      methods.reset({
        ...response,
        image: response.image?.versions?.large?.url ?? null,
        documents: response.documents?.map((document) => ({
          ...document,
          documentId: document.id,
        })),
      });
      toast.success(<Trans>Page updated successfully!</Trans>);
    } catch (error) {
      handleFormError(error, methods.setError);
    }
  });

  return (
    <Wrapper
      header={<Trans>Edit page</Trans>}
      backLink='/pages'
      extraActions={
        <a
          href={`${import.meta.env.VITE_FRONTEND_URL}/paginas/${page.slug}`}
          target='_blank'
          rel='noreferrer'
        >
          <Button variant='secondary'>
            <Trans>Preview</Trans>
          </Button>
        </a>
      }
    >
      <FormProvider {...methods}>
        <PageForm onSave={onSave} />
      </FormProvider>
    </Wrapper>
  );
}
