import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Table } from 'flowbite-react';
import { Button } from '@tremor/react';
import { msg, Trans } from '@lingui/macro';
import { i18n } from '@lingui/core'; // eslint-disable-line import/no-extraneous-dependencies

import { Wrapper, Card, ConfirmModal } from '@/components';
import {
  getAdminPremiseQueryOptions,
  getAdminPremiseSuspenseQueryOptions,
  getAdminPremiseQueryKey,
  useResetIdentifier,
  useIdentifierFile,
} from '@/api';
import { PremiseSustainabilityActionInterest } from '@/api/model';
import { formatDateTime, formatNumber, fileDownload } from '@/utils';
import pageBackground from '@/assets/images/page_background.png';

export const Route = createFileRoute('/_authenticated/premises/$premiseId')({
  loader: async ({ context: { queryClient }, params: { premiseId } }) =>
    queryClient.ensureQueryData(
      getAdminPremiseSuspenseQueryOptions(Number(premiseId)),
    ),
  component: ShowPremise,
});

const interests = {
  [PremiseSustainabilityActionInterest.yes]: msg`Yes`,
  [PremiseSustainabilityActionInterest.no]: msg`No`,
  [PremiseSustainabilityActionInterest.unknown]: msg`Unknown`,
  [PremiseSustainabilityActionInterest.in_order]: msg`In order`,
};

function ShowPremise() {
  const premiseId = Route.useParams({
    select: ({ premiseId }) => Number(premiseId),
  });
  const { queryClient } = Route.useRouteContext({
    select: ({ queryClient }) => ({ queryClient }),
  });
  const { data: premise } = useSuspenseQuery(
    getAdminPremiseQueryOptions(premiseId),
  );
  const [openResetIdentifierModal, setOpenResetIdentifierModal] =
    useState(false);
  const { mutateAsync: resetIdentifier } = useResetIdentifier({
    mutation: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          queryKey: getAdminPremiseQueryKey(premiseId),
        });
      },
    },
  });
  const { mutateAsync: identifierFile } = useIdentifierFile({
    mutation: {
      async onSuccess(response) {
        const url = window.URL.createObjectURL(new Blob([response]));
        fileDownload(pageBackground, `${premise.address}.png`);
        fileDownload(url, `${premise.address}-bewonerscode.png`);
      },
    },
  });

  const renderAuditStatus = (status: string | string[] | undefined) => {
    if (!status) return null;

    if (Array.isArray(status)) {
      if (status.at(0) === undefined) return null;

      return status.at(0)!;
    }

    return status;
  };

  const renderAudits = () => {
    const audits = premise.audits?.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    return (
      <Table striped>
        <Table.Body>
          {audits?.length ? (
            audits.map((audit) => (
              <Table.Row key={audit.id}>
                <Table.Cell>{formatDateTime(audit.createdAt)}</Table.Cell>
                <Table.Cell>
                  {Object.hasOwn(audit.auditedChanges, 'identifier') &&
                    renderAuditStatus(
                      audit.auditedChanges.identifier as string | string[],
                    )}
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell>
                <Trans>No history</Trans>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  };

  const renderEmails = () => {
    const { emails } = premise;

    return (
      <Table striped>
        <Table.Body>
          {emails?.length ? (
            emails.map((email) => (
              <Table.Row key={email}>
                <Table.Cell>{email}</Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell>
                <Trans>No emails</Trans>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  };

  const renderSustainabilityActions = () => {
    const actions = premise.premiseSustainabilityActions;

    return (
      <Table striped>
        <Table.Head>
          <Table.HeadCell>
            <Trans>Sustainability action</Trans>
          </Table.HeadCell>
          <Table.HeadCell>
            <Trans>Status</Trans>
          </Table.HeadCell>
          <Table.HeadCell>
            <Trans>Year completed</Trans>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {actions?.length ? (
            actions.map((action, index) => (
              <Table.Row key={action.id ?? index}>
                <Table.Cell>{action.sustainabilityAction.title}</Table.Cell>
                <Table.Cell>
                  {action.interest ? i18n._(interests[action.interest]) : '-'}
                  {action.solarPanelCount ? ` (${action.solarPanelCount})` : ''}
                </Table.Cell>
                <Table.Cell>{action.yearCompleted ?? '-'}</Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={3}>
                <Trans>No sustainability actions</Trans>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  };

  return (
    <Wrapper
      header={premise.address}
      backLink='/premises'
      extraActions={
        <>
          <Button
            variant='secondary'
            onClick={() => {
              setOpenResetIdentifierModal(true);
            }}
          >
            <Trans>Reset identifier</Trans>
          </Button>
          <Button
            variant='secondary'
            onClick={async () => {
              await identifierFile({ id: premiseId });
            }}
          >
            <Trans>Download identifier letter</Trans>
          </Button>
        </>
      }
    >
      <div className='grid grid-cols-3 gap-4'>
        <div className='col-span-2 flex flex-col gap-4'>
          <Card title={<Trans>General information</Trans>} padding={false}>
            <Table striped>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Trans>Identifier</Trans>
                  </Table.Cell>
                  <Table.Cell>{premise.identifier}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Trans>Living area</Trans>
                  </Table.Cell>
                  <Table.Cell>
                    {premise.livingArea
                      ? `${formatNumber(Number(premise.livingArea))} m2`
                      : '-'}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Trans>Average electricity consumption</Trans>
                  </Table.Cell>
                  <Table.Cell>
                    {premise.averageElectricityConsumption
                      ? `${formatNumber(Number(premise.averageElectricityConsumption))} kWh`
                      : '-'}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Trans>Average gas consumption</Trans>
                  </Table.Cell>
                  <Table.Cell>
                    {premise.averageGasConsumption
                      ? `${formatNumber(Number(premise.averageGasConsumption))} m3`
                      : '-'}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Trans>Building type</Trans>
                  </Table.Cell>
                  <Table.Cell>{premise.buildingType ?? '-'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Trans>Construction year</Trans>
                  </Table.Cell>
                  <Table.Cell>{premise.constructionYear ?? '-'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Trans>Energy label</Trans>
                  </Table.Cell>
                  <Table.Cell>{premise.energyLabel ?? '-'}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Card>
          <Card title={<Trans>Sustainability actions</Trans>} padding={false}>
            {renderSustainabilityActions()}
          </Card>
        </div>
        <div className='flex flex-col gap-4'>
          <Card title={<Trans>History</Trans>} padding={false}>
            {renderAudits()}
          </Card>
          <Card title={<Trans>Emails</Trans>} padding={false}>
            {renderEmails()}
          </Card>
        </div>
      </div>
      <ConfirmModal
        show={openResetIdentifierModal}
        showModal={setOpenResetIdentifierModal}
        onConfirm={async () => {
          await resetIdentifier({ id: premiseId });
          setOpenResetIdentifierModal(false);
        }}
        onCancel={() => {
          setOpenResetIdentifierModal(false);
        }}
      />
    </Wrapper>
  );
}
